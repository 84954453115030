import React, { Component } from 'react'
import './Message.css';
import profileImg from '../../img/profilePlaceholder.jpg';
import friebeltje from '../../img/spreekwolkje_fiebeltje.png';
import Modal from 'react-responsive-modal';

export default class Message extends Component {

    constructor(props) {
        super(props)
        this.state = {
           // account: props.accountData,
            open: false
        }
    }


    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false }); 
    };

    render() {
        const { open } = this.state;
        return ( 
            <div>
            <div className='message'>
                <div className='messageHeader'>
                    <div className='messageHeaderLeft'>
                        <img src={profileImg} className="profilPicSmall" alt="profile" />
                        {this.props.messageData.sender} schreef op {this.props.messageData.dateSent}:  
                </div>
                <div className="MessageView_right_close">
                    <div className="MessageView_Message__delMsgBtn" onClick={this.onOpenModal}>x</div>
                </div>
                </div>

                <div className='messageBody'>
                    {this.props.messageData.message}
                </div>
                <div>
                    <Modal open={open} onClose={this.onCloseModal} center>
                        <div className="modalBody" >
                            Je staat op het punt om een bericht uit de app te verwijderen.
                        </div>
                        <div className="btn" onClick={(event) => { this.props.deleteMethod(); this.onCloseModal(); }} >Ja, Verwijder.</div>
                    </Modal>
                </div>
            </div>
             <img src={friebeltje} className="Friebeltje" alt="profile" />
             </div>
                )   
            }
        }

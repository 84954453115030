import React, { Component } from 'react'
import './RolesUser.css';
import Select from 'react-select';
import Modal from 'react-responsive-modal';

const selectStyles = {
    control: base => ({
      ...base,
      fontFamily: 'ComicNeueAngular'
    }),
    menu: base => ({
      ...base,
      fontFamily: 'ComicNeueAngular'
    })
  };

export default class User extends Component {

    constructor(props) {
        super(props)

        this.state = {
            rollen: props.musicalRollen,
            roleOptions: [],
            open: false, 
        }
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    handleChange = (selectedOption) =>{
    
        this.props.handleSelect(selectedOption, this.props.index)
       // this.setState({ selectedOption });
    }

    render() {

       // console.log('USER = ',this.props.user )
        const { open } = this.state;
        let selectedRoles = []
        let roleOptions = this.props.musicalRollen.filter(function(rolec) {
            if (rolec === undefined) {
        
              return false; // skip
            }
            return true;
          }).map((role, index) => {
           // console.log('ROLE = ', role )
            if(role){
            if(this.props.user[1] && this.props.user[1].length){
                if(this.props.user[1].includes(role.rolid)){
                    if(role){
                        selectedRoles.push({ key: index, value: role, label: role.naam })
                    }
                }
            }
            }
            if(role){
                return ({ key: index, value: role, label: role.naam })
            }else{
                return ({ key: index, value: null, label: ""})
            }
        })
        
        

        return (
            <div className='RolesUser__rollenUser' >
                <div className='RolesUser__headerRollen' >
                    <div className='RolesUser__Name'>{this.props.index+1}. {this.props.user[0]}</div>
                <div className="RolesView_right_close">
                    <div className="MessageView_Message__delMsgBtn" onClick={this.onOpenModal}>x</div>
                </div>  
                </div>
                <Select
                    value={selectedRoles}
                    isMulti
                    name="colors"
                    options={roleOptions}
                    styles={selectStyles}
                    className="basic-multi-select"
                    onChange={this.handleChange}
                    placeholder= "selecteer een rol..."
                    classNamePrefix="Voeg rollen toe ..."
                />
                    <div className='letoptext'>
                    Heeft de leerling meerdere rollen? Voeg die dan hierboven toe.
                    </div>
                <Modal open={open} onClose={this.onCloseModal} center>
                    <div className='modalBody'>
                        Je staat op het punt om een leerling uit de app te verwijderen.
                    </div>
                    <div className="btn" onClick={(event) => { this.props.deleteMethod(); this.onCloseModal(); }} >Ja, Verwijder.</div>
                </Modal>
            </div>
        )
    }
}


import React, { Component } from 'react';
import './App.css';
import { observer, inject } from 'mobx-react';
import logo from './img/logo.png';
import RolesView from './components/RolesView/RolesView';
import MessageView from './components/MessageView/MessageView';
import LoginView from './components/LoginView/LoginView';

import { withFirebase } from './components/Firebase';
import { compose } from 'recompose';



class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedScreen: <RolesView />,
            cookies: []
        }
    }

    getCharsBefore(str, chr) {
        var index = str.indexOf(chr);
        if (index !== -1) {
            return (str.substring(0, index));
        }
        return ("");
    }

    getUrlVars() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });
        return vars;
    }

    getUrlParam(parameter, defaultvalue) {
        var urlparameter = defaultvalue;
        if (window.location.href.indexOf(parameter) > -1) {
            urlparameter = this.getUrlVars()[parameter];
        }
        return urlparameter;
    }

    listCookies() {
        let sessioncookie = ''
        //let sessionname =''
        let id = ''
        let name = ''
        // let email = ''
        // var jsCookies = this.state.cookies
        var theCookies = document.cookie.split(';');
        theCookies.map((cookie, index) => {
            if (cookie.includes('wp_woocommerce_session')) {

                // console.log('wp_woocommerce_session found')

                sessioncookie = cookie.split('=')[1]
                id = this.getCharsBefore(sessioncookie, '%')
                sessioncookie = cookie.split(id)[1]
            }


            return cookie;
        })

        let loginObj = {
            id: id,
            cookie: name + sessioncookie
        }
        return loginObj;

    }

    componentDidMount() {
        this.listener = this.props.firebase.auth.onAuthStateChanged(
            (authUser) => {

                authUser
                    ? this.props.SessionStore.setAuthUser({ authUser })
                    : this.props.SessionStore.setAuthUser({ authUser: null });
            },
        );
    }

    componentWillUnmount() {
        this.listener();
    }

    catchString = (instring) => {
        console.log(instring)
    }

    checkSelect = () => {
        // if(this.props.UserStore.groepen.length > 1){
        return (
            <div className="selectBar">
                <div onClick={() => this.toRepenRoer()} >
                    <img src={logo} className="img-fluid" alt="Logo" />
                </div>

                <div className="menuItem" onClick={() => {
                    //  this.props.UserStore.getRolverdeling()
                    this.setState({ selectedScreen: <RolesView /> })
                }}>Rollen
                </div>
                <div className="menuItem" onClick={() => {
                    this.setState({ selectedScreen: <MessageView /> })
                }}>Berichten
                </div>

                {this.props.UserStore.loggedin === true && <div className="logOut" onClick={() => this.logout()}>Log uit</div>}
            </div>
        )
        //}
    }

    checkLoggedin = () => {
        console.log('checkLoggedin')
        if (this.props.UserStore.loggedin === true) {
            console.log('checkLoggedin- true')
            return (
                <div>
                    {this.checkSelect()}{this.state.selectedScreen}
                </div>)
        } else {
            return (
            <div>
                <div className="selectBar">
                    <div onClick={() => this.toRepenRoer()} >
                        <img src={logo} className="img-fluid" alt="Logo" />
                    </div>

                </div>
                <LoginView />
            </div>)
        }
    }



    logout = () => {

        var data = {
            id: 0,
            cookie: 'nocookie'
        }
        localStorage.setItem('rnr_data', JSON.stringify(data));

        window.location.href = 'https://www.repenroer.nl/app';
    }

    toRepenRoer = () => {
        window.location.href = 'https://www.repenroer.nl';
    }


    render() {
        return (
            <div className="container">
                {this.checkLoggedin()}
            </div>
        );
    }
}

export default compose(withFirebase, inject('UserStore', 'SessionStore', 'MessageStore'), observer)(App);

import { observable, action, decorate } from 'mobx';
import constants from '../components/constants';
class UserStore {
    loggedin = observable.box(false);
    UserData = observable({});
    oudeMus = ['9315', '9316', '9317', '9318', '9319', '9363', '9364', '9365', '9366', '9367', '9368', '9369', '9370', '9371', '944'];

    //TODO change to real data
    notificationGroup = observable.box('0000-0000-00');
    musicalIdTemp = '';
    AccountData = observable({});
    leerlingRollIdArray = observable([]);
    roleObjects = observable([]);
    cookie = observable.box('');
    rollenMartix = observable([]);
    musicalData = observable({});
    scriptObject = observable({});
    musicalId = observable.box('');
    groepen = observable([]);
    selectedGroup = observable.box('0');
    scriptId = observable.box('');
    scriptLoaded = observable.box(false);
    rollen = observable([]);
    rollenInScript = observable([]);
    rollenUitgedeeld = observable([]);

    mainUrl = observable.box('');
    mainKey = observable.box('');
    username = observable.box('');
    password = observable.box('');
    live = observable.box(false);
    error = observable.box('');
    scriptError = observable.box('');

    checkLive = () => {
        this.mainKey = 'live12345API'

    }
    logout = (inError = null) => {
        this.loggedin = false;

        var data = {
            id: 0,
            cookie: 'nocookie'
        }
        localStorage.setItem('rnr_data', JSON.stringify(data));

        //this.notificationGroup = '0000-0000-00'
        this.AccountData = {}
        // this.rollenMartix = []
        this.UserData = {}
        this.scriptObject = {}
        this.musicalData = {}
        this.scriptError = '';

        //  localStorage.clear('rnr_data')
    }

    setRolverdeling(inMatrix) {
        this.rollenMartix = inMatrix
    }

    setSelectedGroup = (inGroupObject) => {

        // console.log("inGroupObject == ", inGroupObject)
        this.selectedGroup = inGroupObject;
        this.scriptId= inGroupObject.value;
       // this.selectedGroup = inGroupObject.key+1;
        this.loadMusicalData(this.AccountData.user_musicalid)
       // this.loggedin = true;
    }


    autoLogin = (callback, inID, inUser, inPass) => {
        this.checkLive();
        this.UserData.id = inID;
       // this.getAccountInfo(inUser, inPass, callback)
    }

    resetStorage = () => {
        var data = {
            id: 0,
            cookie: 'nocookie'
        }
        localStorage.setItem('rnr_data', JSON.stringify(data));

    }

    getAccountInfo = (inUser, inPass, callback) => {
        this.checkLive();
        let encodedpass = encodeURIComponent(inPass);

        fetch(`${constants.endPoint}/get-current-user?username=${inUser}&password=${encodedpass}`, {

            method: 'GET',
            headers: {
                'cache-control': 'no-cache'
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
               
                this.AccountData = responseJson

                if (!responseJson.user_id) {
                    this.resetStorage()
                    callback('Je gebruikersnaam en/of wachtwoord zijn onjuist...')

                    return;
                } else {

                    //save user id and cookie local
                    var data = {
                        id: responseJson.user_id,
                        cookie: 'no Cookie used'
                    }
                    //
                    localStorage.setItem('rnr_data', JSON.stringify(data));
                    this.preloadMusicalData(responseJson.user_musicalid)
                }

                // console.log("getAccountInfo -  groepen", responseJson.user_scriptid )

                if (responseJson.user_scriptid) {

                    let str = responseJson.user_scriptid.replace(/\s/g, "");
                    this.groepen = str.split(',');

                } else {
                    if (this.oudeMus.includes(responseJson.user_musicalid)) {
                        this.groepen = ['0'];
                    } else {
                        callback('Er gaat wat mis. Geen script of musical zonder script gevonden voor dit account, neem even contact met ons op.')
                        return;
                    }
                }

                if (this.groepen.length > 1) {
                    callback('MORE_GROUPS')
                    return;
                } else if (this.groepen.length === 1) {
                    callback('MORE_GROUPS')
                    return;
                } else {

                    callback('Geen scripts en/of groepen toegewezen')
                }
                //this.getUserOrderData( responseJson.activecampaign_for_woocommerce_ac_customer_id, callback);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    handleDataLoaded = () => {
        console.log('loaded...')
        this.loggedin = true;
    }

    preloadMusicalData = (inId) => {

      

        fetch(`${constants.endPoint}/musical/${inId}`, {  //MUSICAL
            method: 'GET',
            headers: {
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson) {

                    this.musicalData = responseJson
                 
                } else {
                }

            })
            .catch((error) => {
                console.error(error);
            });
    }



    loadMusicalData = (inId) => {


        fetch(`${constants.endPoint}/musical/${inId}`, {  //MUSICAL
            method: 'GET',
            headers: {
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson) {

                    this.musicalData = responseJson
                    this.rollen = responseJson.musical_rollen
                    this.getRolverdeling(this.handleDataLoaded)
                    //callback()
                } else {
                }

            })
            .catch((error) => {
                console.error(error);
            });
    }


    //TOOLS: 

    objectFindByKey(array, key, value) {
        for (var i = 0; i < array.length; i++) {
            if (array[i][key] === value) {
                return array[i];
            }
        }
        return null;
    }

    convertUnicode(input) {
        return input.replace(/\\u(\w\w\w\w)/g, function (a, b) {
            var charcode = parseInt(b, 16);
            return String.fromCharCode(charcode);
        });
    }



    getRolverdeling = (callback) => {

        this.notificationGroup = `${this.AccountData.user_id}-${this.AccountData.user_musicalid}-${this.scriptId}-${this.selectedGroup.key+1}`
        this.getMusicalData(callback)

    }

    getMusicalData = (callback) => {

        if (this.musicalData.scripts && this.musicalData.scripts.length > 0) {
            this.scriptObject = this.musicalData.scripts.find(item => item.idcode === this.selectedGroup.value);

            if (this.scriptObject) {
                this.loadAndCheckScript(this.scriptObject)
               
                // show logged in after checking script?
                //this.loggedin = true;

            } else {
                callback("ScriptError: Het juiste script kon niet geladen worden: script nr: " + this.selectedGroup.value)
                this.scriptError = "ScriptError: Het juiste script kon niet geladen worden.."
                this.rollenInScript = this.rollen
            }
        } else {

            this.rollenInScript = this.rollen
            if (this.oudeMus.includes(this.AccountData.user_musicalid.toString())) {

                this.loggedin = true;
            } else {
                // callback("ScriptError: Het juiste script kon niet geladen worden: script nr: "+this.selectedGroup.value)
                this.scriptError = "ScriptError: Het juiste script kon niet geladen worden.."
            }

        }

    }

    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }


    loadAndCheckScript = (inScriptOBJ) => {

        //  OLD    fetch(`${inScriptOBJ.jsonfile}?cookie=${this.cookie}&key=${this.mainKey}`, {
        fetch(`${inScriptOBJ.jsonfile}`, {
            method: 'GET',
        })
            .then((response) => response.json())
            .then((responseJson) => {

                let idArray = []

                for (let index = 0; index < responseJson.length; index++) {
                    if (responseJson[index].roleid) {
                        if (responseJson[index].roleid.length !== 0) {
                            let subIdArray = responseJson[index].roleid.toString().replace(/, +/g, ",").split(",").map(Number);
                            subIdArray.forEach(element => {
                                idArray.push(element)
                            });
                        }
                    }
                }


                let uniqueIDsInThisScript = idArray.filter(this.onlyUnique);



                //this.scriptId = "JSON LOADED!"
                let nieuweLijst = uniqueIDsInThisScript.map((scriptID, index) => {

                    for (let i = 0; i < this.rollen.length; i++) {

                        if (scriptID == this.rollen[i].rolid) {

                            return (this.rollen[i])
                        }
                    }
                })

                //neiuweLijst[i].roleid
                nieuweLijst.sort(function (a, b) {
                    return a.rolid - b.rolid;
                });


                this.rollenInScript = nieuweLijst;
                this.loggedin = true;

            })
            .catch((error) => {
                console.error(error);
                this.error = "CORS issue...."
            });
    }

}

decorate(UserStore, {
    loggedin: observable,
    UserData: observable,
    scriptObject: observable,
    musicalData: observable,
    musicalId: observable,
    rollen: observable,
    cookie: observable,
    leerlingRollIdArray: observable,
    rollenMartix: observable,
    roleObjects: observable,
    user_id: observable,
    generateCookie: action,
    getRolverdeling: action,
    setRolverdeling: action,
    getMusicalData: action,
    scriptId: observable,
    scriptLoaded: observable,
    updateRolverdeling: action,
    rollenInScript: observable,
    rollenUitgedeeld: observable,
    error: observable,
    scriptError: observable,
    logout: action,
    autoLogin: action
});

//export default new UserStore();
export default new UserStore();

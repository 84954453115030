import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import User from '../RolesView_RolesUser/RolesUser'
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';

import './RolesView.css';
import '../../App.css';
//import { AccountData } from '../../testData/TestData';
class RolesView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name: 'Lijst van leerlingen:',
            newUser: '',
            userName: '',
            rollenMartix: [],
            feedbacktext: '',
            savingRollen: false,
            newNotificationID: true,
            saved: false,
            error: this.props.UserStore.error,
            loading: false,
            hasUnsavedChanges: false,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.props.firebase.rolverdeling(this.props.UserStore.notificationGroup).once('value')
            .then((snapshot) => {
                const rollenObject = snapshot.val();
                if (rollenObject) {

                    const matrix = rollenObject.map((role) => {
                        return role;
                    })
                    this.setState({
                        loading: false,
                        rollenMartix: matrix
                    })
                    this.props.UserStore.setRolverdeling(matrix)
                } else {
                    this.setState({
                        newNotificationID: false,
                        rollenMartix: [],
                    })
                    this.props.UserStore.setRolverdeling([])
                }
            })

            .catch((error) => {
                console.log('error ', error)
            })
    }

    formSubmitted = (event) => {

        event.preventDefault();
        if (this.state.newUser === '') {
            this.setState({
                feedbacktext: 'Vul eerst even een naam in..'
            })
            return
        }


        this.setState({

            rollenMartix: [...this.state.rollenMartix,

            [this.state.newUser.trim(),
            []],//hiermee kan de juiste Rol bij de juiste leerling gevonden worden.

            ],
            feedbacktext: '',
            newUser: '',
            hasUnsavedChanges: true,
        });
        this.textInput.focus();
    }

    resetRollen = () => {

        this.setState({

            rollenMartix: this.props.UserStore.rollenMartix,
            hasUnsavedChanges: false
        });

    }


    updateUser(event) {
        var regex = /[^a-z0-9-_ ]/gi;
        //var regex = /[^a-z0-9-_.@ ]/gi;
        var newValue = event.target.value.replace(regex, "");
        this.setState({ newUser: newValue })
    }


    handleKeyPress = (event) => {

        if (event.key === 'Enter') {
            this.formSubmitted(event)
        }
    }

    deleteUser(index) {
        let userArray = this.state.rollenMartix;
        userArray.splice(index, 1);
        this.setState({
            rollenMartix: userArray,
            hasUnsavedChanges: true

        })
    }

    addRoles = (inSelection, inIndex) => {

        let userArray = this.state.rollenMartix;
        userArray[inIndex][1] = [];
        if (inSelection !== null) {
            inSelection.forEach((rolObject, index) => {
                userArray[inIndex][1].push(rolObject.value.rolid)
            });
        }
        this.setState({
            rollenMartix: userArray,
            hasUnsavedChanges: true
        })
    }

    undo = () => {
        // console.log('reset to last save')
        this.setState({ rollenMartix: JSON.parse(this.props.UserStore.rollenMartix.toString()) })
    }

    saveList = () => {
        // this.props.UserStore.updateRolverdeling([])
        this.saveRolverdelingToFirebase(this.state.rollenMartix, this.saveFeedback)
        this.setState({
            saved: true,
            savingRollen: true,
            feedbacktext: 'De rolverdeling wordt opgeslagen...',
            hasUnsavedChanges: false
        })

    }

    saveRolverdelingToFirebase = (inRolverdeling, callback) => {


        if (this.state.newNotificationID) {
            this.props.firebase.newRolverdeling(this.props.UserStore.notificationGroup).set(inRolverdeling)
                .then(() => {

                    callback()

                    this.setState({ newNotificationID: false });
                    //this.props.history.push(ROUTES.HOME);
                })
                .catch(error => {
                    callback('rolverdeling is niet opgeslagen.')
                    this.setState({ error });
                });

        } else {
            this.props.firebase.rolverdeling(this.props.UserStore.notificationGroup).set(inRolverdeling)
                .then(() => {
                    console.log('Rolverdeling SET')
                    callback()
                })
                .catch(error => {
                    callback('rolverdeling is niet opgeslagen.')
                    this.setState({ error });
                });
        }
    }


    saveFeedback = (inText) => {

        this.setState({
            feedbacktext: inText,
            savingRollen: false,
        })
    }

    checkSavingButton = () => {
        if (!this.state.savingRollen) {
            return (<div className="btn" onClick={this.saveList.bind(this)}>Save</div>)
        } else {
            return (<div className="btn_loading"><div className="loader"></div> Saving ...</div>)
        }
    }


    render() {
        let users = [];
        var rollenVergeven = [];
        if (this.state.rollenMartix && this.state.rollenMartix.length) {
            users = this.state.rollenMartix.map((leerling, index) => {
                if (leerling[1] && leerling[1].length) {
                    for (let i = 0; i < leerling[1].length; i++) {
                        rollenVergeven.push(leerling[1][i])

                    }
                }
                return (<User key={index} index={index} user={leerling} handleSelect={this.addRoles} musicalRollen={this.props.UserStore.rollenInScript} deleteMethod={() => this.deleteUser(index)} />)
            })
        }

        let rollenTeVergeven = [];
        if (this.props.UserStore.AccountData.user_musicalid > 3230) {
            rollenTeVergeven = this.props.UserStore.rollenInScript.map((rol, index) => {
                let display = true

                for (let i = 0; i < rollenVergeven.length; i++) {
                    if (rol) {
                        if (rollenVergeven[i] === rol.rolid) {
                            display = false;
                        }
                    } else {
                        // niks.. deze rol mag gewoon getoond worden

                    }
                }
                if (display) {
                    if (rol) {
                        return (<div className='RoleView__feedback_rollenover_item'>{rol.naam}</div>)
                    }

                }
            })
            if (rollenTeVergeven.length !== 0) {
                rollenTeVergeven.unshift(<div>Hieronder zie je de rollen die nog niet aan een leerling zijn toegewezen: </div>)
            }
        }
        // console.log(this.props.UserStore.AccountData)

        return (
            <div className="RoleView__container">
                {this.state.hasUnsavedChanges &&
                    <div className="save-indicator">
                        <span>Let op, je wijzigingen zijn nog niet opgeslagen...</span>
                        <div className="actions">
                            <a href="#" className="cancel-link" onClick={this.resetRollen}>Cancel aanpassingen</a>
                            <div className="ButtonRow">
                                {this.checkSavingButton()}
                            </div>
                        </div>
                    </div>}
                <div className="RoleView__Columns">
                    <div className="RoleView__Columns__left">
                        <div>
                            <h1> "{this.props.UserStore.musicalData.name}"</h1>
                            <h3>Welkom {this.props.UserStore.AccountData.user_display_name} van {this.props.UserStore.AccountData.user_school ? this.props.UserStore.AccountData.user_school : 'Een basisschool'}.
                                Jullie spelen de musical: "{this.props.UserStore.musicalData.name}" ({this.props.UserStore.selectedGroup.label})
                            </h3> </div>
                        <div className="vert__spacer" />
                        <div className="cast__list">
                            <h2>De Cast:</h2>
                            <div className="vert__spacer" />
                            {users}
                            <div className="vert__spacer" />
                            <div className="RoleView__bottom">
                                <div className="Feedback">{this.state.feedbacktext} </div>
                                {/* <div className="Feedback">{this.props.UserStore.scriptError} </div> */}
                                <div className="InputRow">
                                    <input
                                        type='text'
                                        maxLength="30"
                                        ref={((input) => { this.textInput = input })}
                                        onChange={(event) => this.updateUser(event)}
                                        className='RoleView__textInput'
                                        placeholder='Naam leerling...'
                                        id="newUser"
                                        name="newUser"
                                        value={this.state.newUser}
                                        onKeyPress={this.handleKeyPress.bind(this)}
                                    />
                                    <div className="RoleView__btn" onClick={this.formSubmitted.bind(this)}>+</div>
                                </div>

                            </div>
                            <div className="ButtonRow">
                                    {this.checkSavingButton()}
                                </div>
                        </div >
                        <div className="vert__spacer" />
                        <div>
                            {rollenTeVergeven}
                        </div>
                    </div>

                    <div className="RoleView__Columns__right">
                        <div> <h1 className="whiteText"> jullie klassencode:</h1>
                            <h1 style={{ color: '#E22380' }}>{this.props.UserStore.notificationGroup}</h1>
                        </div>
                        <div className="RollView_right_123block" >
                            <div className="RollView_right_123block_number">
                                <p className="Black__Number"> 1</p>
                            </div>
                            <div>
                                <h2> Voer leerlingen in, en verdeel de rollen</h2>
                                <p>Vul de namen van je leerlingen in (zorg dat de namen uniek zijn). Selecteer vervolgens een of meerdere rollen bij elke leerling.
                                    Je kunt dit altijd nog aanpassen. Je kunt ook personen toevoegen zonder rol. Bijvoorbeeld jezelf, hulpouders of mensen van de techniek.</p>
                            </div>
                        </div>
                        <div className="RollView_right_123block" >
                            <div className="RollView_right_123block_number">
                                <p className="Black__Number"> 2</p>
                            </div>
                            <div>
                                <h2> Sla de rolverdeling op</h2>
                                <p> Vergeet niet de rolverdeling op te slaan.</p>
                            </div>
                        </div>
                        <div className="RollView_right_123block" >
                            <div className="RollView_right_123block_number">
                                <p className="Black__Number"> 3</p>
                            </div>
                            <div>
                                <h2> Nodig leerlingen uit!</h2>
                                <p> Je leerlingen kunnen de app gratis downloaden op hun telefoon. de "Rep en Roer Oefenapp" is te vinden in de Apple Appstore en de Google Play store. Ga naar de <a href="https://repenroer.oefenapp.nl">Online Oefenapp</a> wanneer je op de computer wilt oefenen. Vervolgens kunnen ze inloggen met de door jou ingevoerde voornaam en de klassencode: {this.props.UserStore.notificationGroup} die hieronder te zien is. </p>
                                <h2 className="whiteText">klassencode:</h2>
                                <h2 style={{ color: '#E22380' }}>{this.props.UserStore.notificationGroup}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default compose(withFirebase, inject('MessageStore', 'UserStore'), observer,)(RolesView);


import React, { Component } from 'react';
import { observable, action, decorate } from 'mobx';
//import fire from '../components/Firebase/firebase';

/// key voor php POST :  3q%]aTe[Q5&'f^:P
class MessageStore extends React.Component {
    messages = observable([]);
    messageFeedback = observable.box([]);
    errorFeedback = observable.box([]);
    users = observable([]); 
    userTokens =  observable([]);

    constructor(props) {
        super(props)

        this.removeDeviceByIndex = this.removeDeviceByIndex.bind(this);
    }

    getMessages = () => {
        // call to server
        return this.messages;
    }

    setUserTokens =(inArray) => {
        this.userTokens = inArray;
    }

    removeDeviceByIndex = (inIndexArray, inTotalArray, inAppleGoogle) => {
        console.log('remove device the index:  ', inIndexArray)
    }



getFormattedDate = () => {

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    return (dd + '-' + mm + '-' + yyyy)
}


}


decorate(MessageStore, {
    messages: observable,
    users: observable,
    getFormattedDate: observable,
    userTokens: observable,
    messageFeedback: observable,
    errorFeedback: observable,
    getMessages: action,
   loadMessages: action,
    saveMessage: action,
    updateMessage: action,
    loadTokens: action,

});


export default new MessageStore();

export default{ 
    pushconfig: {
     apiKey: "AIzaSyCNzLCaxH9xk5YTPQ07VU9cL3RTuZUC4oM",
    
    } ,
 
liveURL: 'https://www.repenroer.nl/app',
endPoint: 'https://www.repenroer.nl/wp-json/strl-app',
    fireUser: 'maarten@zilvervleut.nl',
    fireP: 'Zethemopmaarten123!',
    Pkey:"3q%]aTe[Q5&'f^:P"
}
   

// liveURL: 'https://www.repenroer.nl/app',
// endPoint: 'https://www.repenroer.nl/wp-json/strl-app',

// liveURL: 'https://nlrepenro-semera.savviihq.com/app',
// endPoint: 'https://nlrepenro-semera.savviihq.com/wp-json/strl-app',
import { observable, action, decorate } from 'mobx';
//import { withFirebase } from '../components/Firebase';
//import { compose } from 'recompose';


class SessionStore {
 
   
authUser =  observable.box(null);;
  constructor(rootStore) {
    this.rootStore = rootStore;
  }
  setAuthUser = (authUser) => {
    //  console.log('ssetting auth user:', authUser)
    this.authUser = authUser;
  };


// loginFireBase = ()=>{

//     console.log('this.props.firebase.', this.props.firebase)
//         let  fireUser= 'maarten@zilvervleut.nl'
//         let fireP= 'Zethemopmaarten123!'
//         this.props.firebase.doSignInWithEmailAndPassword(fireUser, fireP)
//           .then(() => {
//               console.log('LOGGED IN (AGAIN')
//             //this.setState({ ...INITIAL_STATE });
//             //this.props.history.push(ROUTES.HOME);
//           })
//           .catch(error => {
//             this.setState({ error });
//           });
    
//     }
}
decorate(SessionStore, {
    authUser: observable,
    setAuthUser: action,
    loginFireBase: action
});


export default new SessionStore();
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import '../../App.css';
import './MessageView.css';
import Message from '../MessageView_Message/Message'
import friebeltje from '../../img/spreekwolkje_fiebeltje.png';

import { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import constants from '../constants';


class MessageView extends Component {

    constructor(props) {
        super(props)
       
        this.state = {
            savingMessage: false, 
            accountData: this.props.UserStore.AccountData,
            name: 'Lijst van leerlingen:',
            newMessage: '',
            errorMessage: '',
            messages: [],
            placeholderName: 'je naam',
            username: this.props.UserStore.AccountData.user_display_name,
            tokens: this.props.MessageStore.users,
            
        };
    }
        componentDidMount() {
            this.setState({ loading: true });
            if(this.props.firebase.messages(this.props.UserStore.notificationGroup)){
            this.props.firebase.messages(this.props.UserStore.notificationGroup).on('value', snapshot => {
                const messageObject = snapshot.val();

                if (messageObject) {
                    const messageList = Object.keys(messageObject).map(key => ({
                        ...messageObject[key],
                        uid: key,
                      }));
                      this.setState({ messages: messageList.reverse(), loading: false });
                    
                  } else {
                    
                    this.setState({ messages: [], loading: false });
                  }
            });
            }else{
                this.setState({ messages: [], loading: false });
            }
        }


        componentWillUnmount() {
            this.props.firebase.messages().off();
          }


    

    updateMessage= (event)=> {
        this.setState({ 
            newMessage: event.target.value ,
            feedback: '',
        })
    }

    updateUsername= (event) =>{
        this.setState({username: event.target.value})
    }

    addMessage = () => {

        this.setState({
            errorMessage: '',
            savingMessage:true,
            feedback :''
        })
        
        if(this.state.newMessage === '') {
            this.setState({
                errorMessage: 'Schrijf je wel even een berichtje?',
                loading:false,
                savingMessage:false,
            })
        }else{
     
        let message = {
            key: this.props.UserStore.mainKey,
            cookie: this.props.UserStore.cookie,
            title: this.props.UserStore.notificationGroup,
            notificationGroup: this.props.UserStore.notificationGroup,  // kan ook de musicalcode zijn
            status: 'publish',
            type: 'messages',
            content: this.state.newMessage,
            name: this.state.username
        }

        let messageString = `key=${this.props.UserStore.mainKey}&cookie=${this.props.UserStore.cookie}&title=${this.props.UserStore.notificationGroup}&content=${this.state.newMessage}&status=publish&type=messages&meta_key[notificationGroup]=${this.props.UserStore.notificationGroup}`

        let fireMessageObject = {
            sender: message.name,
            message: message.content,
            notificationGroup: message.notificationGroup,
            dateSent: this.props.MessageStore.getFormattedDate()
        }
        this.props.firebase.messages(this.props.UserStore.notificationGroup).push(fireMessageObject)
        .then(() => {
        
        })
        .catch(error => {
          this.setState({ error });
        });
        this.pushNotification(fireMessageObject)
        this.setState({
            newMessage: '',
            savingMessage: false, 
        })
        this.textInput.focus();
        }
    }

    pushNotification = (inMessagObject) => {
        //get all the pushtokens from Firebase
        var pushTokens = [];
       // var initialUserGroup = [];
        var idexesToDelete = [];
       //var appleGoogleResponse= {}

       this.props.firebase.users(this.props.UserStore.notificationGroup).once('value', snapshot => {
        const userGroup = snapshot.val(); 

            for (let key in userGroup) {
                if (userGroup.hasOwnProperty(key)) {
                    pushTokens.push(userGroup[key].ept.toString())
                }
            }
            var unique = pushTokens.filter( (value, index, self) =>{ 
                return self.indexOf(value) === index;
            } );

            let messageArray = unique.map((token) => {
                return ({
                    "to": token.toString(),
                    "title": `Van: ${inMessagObject.sender}`,
                    "body": inMessagObject.message,
                    "badge": 1
                })
            })

            var messagePost = {
                key: constants.Pkey,
                message: messageArray
            }

            //fetch('https://www.repenroer.nl/app/notifications/notifications.php', {
            fetch(`${constants.liveURL}/notifications/notifications.php`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(messagePost)
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    var cleanString = responseJson.replace("///", "/")
                    var cleanJson = JSON.parse(cleanString)
    
                    if (cleanJson.error) {
                    
                        this.errorFeedback = cleanJson.error
                    } else {
                        console.log('Notification sent!  ', cleanJson)
                        var sentIds = []
                        var ids = []
                        cleanJson.data.forEach((ticket, index) => {
                            if (ticket.status === "error") {
                                
                                if (ticket.details.error === 'DeviceNotRegistered') {
                            
                                    idexesToDelete.push(index)
                                } else {
                    
                                }
                            }else{
                                sentIds.push(ticket.id)
                            }
                            ids.push( ticket.id)
                        })
                    }
                   
                    return sentIds;
                })
                .then((ids) =>{
              
                    var receiptPost = {
                        key: "3q%]aTe[Q5&'f^:P",
                        ids: {ids}
                    }
            
                    fetch(`${constants.liveURL}/notifications/receipts.php`, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(receiptPost)
                    })
                        .then((response) => response.json())
                        .then((responseJson) => {
                  
                            console.log('responce receipt',responseJson )
                           this.doneLoading(responseJson)
                        })
            
                        .catch((error) => {
                            console.error(error);
                        })
            
                })
                .catch((error) => {
                    console.error(error);
                })
                
        });
       
    }


    doneLoading = (inFeedback) => {

        this.setState({ 
            newMessage: '',
            savingMessage:false,
            loading:false
        });
    }


    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.addMessage()
        }
    }

    deleteMessage= (inId) =>{

            this.props.firebase.delmessage(this.props.UserStore.notificationGroup, inId).remove()
    }

    checkLoadingButton = () => {
        if(this.state.savingMessage == false){
            return (<div className="btn" onClick={this.addMessage.bind(this)}>Verstuur!</div>)
        } 
            return ( <div className="btn_loading"><div className="loader"></div>Sending ...</div>)
    }


    render(){

        let messages = this.state.messages.map((val, key) => {
            return (<Message key={key} messageData={val} accountData={this.props.UserStore.AccountData} deleteMethod={() => this.deleteMessage(val.uid)} />)  
        })

        return (
            <div className="MessageView__container">
                <div className="RoleView__Columns">
                    <div className="MessageView__Columns__left">
                        <h2>Verstuur een bericht:</h2>
                        <div className="RollView_left_sendmessage" >
                        <p>Verstuur een bericht naar de leerlingen die deze musical spelen. Deze krijgen hiervan een notificatie op hun telefoon.</p>
                        <textarea
                            type='text'
                            ref={((input) => { this.textInput = input })}
                            onChange={(event) => this.updateMessage(event)}
                            className='MessageView__textCloud'
                            placeholder="schijf hier je berichtje..."
                            id="newMessage"
                            name="newMessage"
                            value={this.state.newMessage}
                        />
                        <div>
                            <img src={friebeltje} className="Friebeltje" alt="" />
                        </div>
                        <p>Afzender</p>
                        <input 
                                type='text'
                               // ref={((input)=> {this.textInput = input})}
                                onChange={(event) => this.updateUsername(event)}
                                className='MessageView__user'
                                placeholder= {this.props.UserStore.AccountData.user_display_name}
                                id="userName"
                                name="userName" 
                                value={this.state.username}
                                />

                        <div className="Feedback"><p> {this.state.errorMessage}</p></div>
                        {this.checkLoadingButton()}
                    </div>
                    </div>
                    <div className="MessageView__Columns__left">
                        <div>
                            <h2>Verzonden berichten:  </h2>
                        </div>
    
                        <div>
                           </div>
                           {messages.length ? (messages) : (
                            <p>Geen berichtjes beschikbaar ...</p>
                            )}
                    </div>
                </div>
            </div>  
        );
    }
}

export default compose(withFirebase, inject('MessageStore', 'UserStore'),observer,)(MessageView);



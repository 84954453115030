
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import constants from '../constants';

const config = { /* COPY THE ACTUAL CONFIG FROM FIREBASE CONSOLE */
    apiKey: constants.pushconfig.apiKey,
    authDomain: "repenroer-12ec7.firebaseapp.com/users",
    databaseURL: "https://repenroer-12ec7.firebaseio.com",
    projectId: "repenroer-12ec7",
    storageBucket: "repenroer-12ec7.appspot.com",
    messagingSenderId: "775640802573",
    appId: "1:775640802573:web:5577a4b47f79a84a2e7c44"
  };
class Firebase {
  
  constructor() {
    app.initializeApp(config);
     /* Helper */

     this.serverValue = app.database.ServerValue;
     this.emailAuthProvider = app.auth.EmailAuthProvider;
 
     /* Firebase APIs */
 
     this.auth = app.auth();
     this.db = app.database();
  }

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);
  

  doSignOut = () => this.auth.signOut();


  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = [];
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***

  users = (notificationgroup) => this.db.ref(`Users/${notificationgroup}`);

  // *** Message API ***

  delmessage = (notificationgroup, uid) => this.db.ref(`messages/${notificationgroup}/${uid}`);

  messages = (uid) => this.db.ref(`messages/${uid}`);

   // *** RolesMatrix API ***

  rolverdeling = (uid) => this.db.ref(`rolverdeling/${uid}`);
  newRolverdeling = (inRolverdeling)  => this.db.ref(`rolverdeling/${inRolverdeling}`);
  // rolverdelingen = () => this.db.ref('rolverdelingen');
}


export default Firebase;


import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import './LoginView.css';
import '../../App.css';
import Select from 'react-select';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';


const selectStyles = {
    control: base => ({
        ...base,
        fontFamily: 'ComicNeueAngular',
        color: '#E22380',

    }),
    menu: base => ({
        ...base,
        fontFamily: 'ComicNeueAngular',
    })
};

class LoginView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            viewState: "LOGIN",
            loading: false,
            username: '',
            password: '',
            messageFeedback: '',
            messageFeedback2: '',
            selectButton: false,
            groups:0
        };

    }
    componentDidMount() {
        let loginCreds = {}
        if (localStorage.getItem('rnr_data')) {
            loginCreds = JSON.parse(localStorage.getItem('rnr_data'))
           
                this.setState({
                    loading: false,
                    messageFeedback: ''
                })
                //this.props.UserStore.autoLogin(this.loadingDone, loginCreds.id, this.state.username, this.state.password)
        }
    }

    loginFireBase = (callback) => {
        let fireUser = 'maarten@zilvervleut.nl'
        let fireP = 'Zethemopmaarten123!'
        this.props.firebase.doSignInWithEmailAndPassword(fireUser, fireP)
            .then(() => {
                callback()
            })
            .catch(error => {
                this.setState({ error });
            });
    }


    updateUsername(event) {
        this.setState({ 'username': event.target.value })
    }
    updatePassword(event) {
        this.setState({ 'password': event.target.value })
    }

    login = () => {
        this.setState({
            loading: true,
            messageFeedback: ''
        })
        this.props.UserStore.getAccountInfo(this.state.username, this.state.password, this.loadingDone)
    }

    loadingDone = (inGroup) => {
      
        this.setState({
            viewState: inGroup,
            loading: false,
            messageFeedback: inGroup,
            groups:this.props.UserStore.groepen.length

        })
        this.loginFireBase(this.fireLoaded)

    }
    fireLoaded = () =>{

        //console.log('firebase loaded')
       
        if(this.props.UserStore.groepen.length === 1){
         
            this.props.UserStore.setSelectedGroup({ key: 0, value: this.props.UserStore.groepen[0], label: "Groep 1"})
            this.loadMusical()
        }
    }

    handleScriptFeedback = (inError) => {
        this.setState({
            viewState: 'MORE_GROUPS',
            loading: false,
            messageFeedback2: inError
        })
    }

    loadMusical = () => {

        this.setState({
            loading: true,
            messageFeedback: '',
            selectButton: false
        })
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.login()
        }
    }

    checkLoadingButton = () => {
        if (this.state.loading) {
            return (<div className="btn_loading"><div className="loader"></div> loading ...</div>)
        } else if (this.state.viewState === 'MORE_GROUPS') {
            if (this.state.selectButton) {
                return (<button className="btn" onClick={this.loadMusical.bind(this)}>Verder</button>)
            }
        } else {
            return (<button className="btn" onClick={this.login.bind(this)}>Log in!</button>)
        }
    }

    handleChange = (selectedOption) => {

       // console.log('selectedOption=', selectedOption)
       
        this.props.UserStore.setSelectedGroup(selectedOption)

        //this.props.UserStore.setSelectedGroup({ key: 0, value: this.props.UserStore.groepen[0], label: "Groep 1"})
        this.setState({
            loading:true,
            selectButton: true
        })
    }

    selectGroup = (inViewState) => {
        let groepOptions = this.props.UserStore.groepen.map((groep, index) => {

            
            let scriptTitle = ''
            if (groep !== '0') {
                // console.log ('selectGroup? ')
                // console.log ('this.props.UserStore.musicalData ? ',this.props.UserStore.musicalData )
                // console.log (' this.props.UserStore.musicalData.scripts ? ', this.props.UserStore.musicalData.scripts )
                if (this.props.UserStore.musicalData && this.props.UserStore.musicalData.scripts) {
                    scriptTitle = " : " + this.props.UserStore.musicalData.scripts.find(script => script.idcode.trim() === groep).scriptTitle;
                }
            }
            return ({ key: index, value: groep, label: "Groep " + (index + 1) + scriptTitle })
        })


        if (inViewState === "MORE_GROUPS") {

            // console.log ('groepOptions = ', groepOptions)

            if (groepOptions.length > 1) {
                return (
                    <div>
                        <p>Welkom {this.props.UserStore.AccountData.user_display_name} van basisschool {this.props.UserStore.AccountData.user_school ? this.props.UserStore.AccountData.user_school : ''}. Selecteer hieronder de klas/groep waarmee je aan de slag wil:</p>
                        <Select
                            value={this.props.UserStore.selectedGroep}
                            isMulti={false}
                            name="colors"
                            options={groepOptions}
                            styles={selectStyles}
                            className="basic-multi-select"
                            onChange={this.handleChange}
                            placeholder="selecteer een groep/klas"
                            classNamePrefix="selecteer een andere groep ..."
                        />
                        <div>
                            <div className="Feedback">{this.state.messageFeedback2}</div>
                        </div>
                    </div>
                )
            } else {
               
                return (
                    <div>
                        <p>Welkom {this.props.UserStore.AccountData.user_display_name} van basisschool {this.props.UserStore.AccountData.user_school ? this.props.UserStore.AccountData.user_school : ''} in {this.props.UserStore.AccountData.user_stad ? this.props.UserStore.AccountData.user_stad: ''}. Je leerlingen kunnen de Mobile App gratis downloaden op hun telefoon of tablet. de "Rep en Roer Oefenapp" is te vinden in de Apple Appstore en de Google Play store.</p>
                       
                        <div>
                            <div className="Feedback">{this.state.messageFeedback2}</div>
                        </div>
                    </div>
                )
            }



        } else {

            return (
                <div>
                    <p>Vul hier je gebruikersnaam en wachtwoord in. Ben je nieuw bij ons? Dan heb je deze per mail ontvangen ('Je account op Rep en Roer').</p>
                    <input
                        type='text'
                        // ref={((input)=> {this.textInput = input})}
                        onChange={(event) => this.updateUsername(event)}
                        className='LoginView__textCloud'
                        placeholder="Je repenroer login naam"
                        id="newMessage"
                        name="newMessage"
                        value={this.state.username}
                    />
                    <input
                        type='password'
                        resize='none'
                        // ref={((input)=> {this.textInput = input})}
                        onChange={(event) => this.updatePassword(event)}
                        className='LoginView__textCloud'
                        placeholder="Je repenroer wachtwoord"
                        id="password"
                        name="password"
                        value={this.state.password}
                    />
                    <div>
                        <div className="Feedback">{this.state.messageFeedback}</div>
                    </div>
                </div>
            )
        }
    }


    render() {
        return (
            <div className="LoginView__container__container">
                <div className="LoginView__container">
                    <div className="LoginView__loginCard2">
                        {this.selectGroup(this.state.viewState)}
                        {this.checkLoadingButton()}
                    </div>
                    <div className="link_left">
                        <a target='_blank' href="https://www.repenroer.nl/mijn-account/lost-password/" rel="noopener noreferrer">Wachtwoord vergeten?</a>
                    </div>
                </div>
            </div>
        );
    }
}


export default compose(withFirebase, inject('UserStore'), observer,)(LoginView);
